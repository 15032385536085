<template>
  <div>
    <c-search-box @enter="getDeptList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="grid"
          title="코스트센터 목록"
          :columns="grid.columns"
          :data="grid.data"
          :editable="editable"
          :usePaging="true"
          :filtering="true"
          :isFullScreen="true"
          :columnSetting="false"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" icon="add" @btnClicked="addRow" v-if="editable" />
              <c-btn label="저장" icon="save" @btnClicked="saveRow" v-if="editable" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'cost-center-manage',
  data() {
    return {
      useFlagItems: [],
      editable: false,
      grid: {
        columns: [
          {
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            required: true,
            style: 'width:200px',
            type: 'plant',
            align: 'center',
            sortable: true,
          },
          {
            name: 'costCenterNo',
            field: 'costCenterNo',
            label: '코스트센터 번호',
            required: true,
            style: 'width:180px',
            type: 'text',
            align: 'center',
            sortable: false,
          },
          {
            name: 'costCenterName',
            field: 'costCenterName',
            label: '코스트센터명',
            required: true,
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'maintenanceFlag',
            field: 'maintenanceFlag',
            label: '정비업무 여부',
            align: 'center',
            style: 'width:160px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'purchaseFlag',
            field: 'purchaseFlag',
            label: '구매업무 여부',
            align: 'center',
            style: 'width:160px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:160px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.costcenter.list.url;
      this.saveUrl = transactionConfig.mdm.costcenter.save.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.param = this.searchParam;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveRow() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getList();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addRow() {
      this.grid.data.push({
        editFlag: 'C',
        plantCd: this.searchParam.plantCd,  // 사업장코드
        costCenterCd: uid(),  // 코스트센터 일련번호
        costCenterNo: '',  // 코스트센터 일련번호
        costCenterName: '',  // 코스트센터 명
        maintenanceFlag: 'N',  // 정비업무 여부
        purchaseFlag: 'N',  // 구매업무 여부
        useFlag: 'Y',  // 사용여부
      })
    },
  }
};
</script>